import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./app/App";
import { BrowserRouter as Router } from "react-router-dom";
import { ReactQueryDevtools } from "react-query-devtools";
import { QueryCache, ReactQueryCacheProvider } from "react-query";
import { ProvideAuth } from "./auth/Auth";

import 'artemis-shared/i18n/I18n';

import moment from "moment";
import "moment/locale/hu";
import { BreakpointProvider } from "artemis-shared/utils/Breakpoint";
import { UrlContext } from "../../shared/utils/UrlContext";
import huHU from 'antd/lib/locale/hu_HU';
import {ConfigProvider} from "antd";
moment.locale("hu");

const swtrigger = 21;

// remove GA cookies//
document.cookie =
  "_gid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=.ijasz.net; path=/;";
document.cookie =
  "_gat=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=.ijasz.net; path=/;";
document.cookie =
  "_ga=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=.ijasz.net; path=/;";
//

const queryCache = new QueryCache({
  defaultConfig: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const queries = {
  xs: "(max-width: 575px)",
  sm: "(min-width: 576px)",
  md: "(min-width: 768px)",
  lg: "(min-width: 992px)",
  xl: "(min-width: 1200px)",
  xxl: "(min-width: 1600px)",
  menu: "(max-width: 1220px)"
};

const urls = {
  competition: "/competition",
  competitions: "/competitions",
  athlete: "/athlete",
  privacy: "/privacy",
};

ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider locale={huHU}>
      <Router>
        <UrlContext.Provider value={urls}>
          <BreakpointProvider queries={queries}>
            <ReactQueryCacheProvider queryCache={queryCache}>
              <ReactQueryDevtools />
              <ProvideAuth>
                <App />
              </ProvideAuth>
            </ReactQueryCacheProvider>
          </BreakpointProvider>
        </UrlContext.Provider>
      </Router>
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
